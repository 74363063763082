<template>
    <div class="spin" v-if="start">
        <div class="ring">
            <div class="lds-dual-ring">
                <div></div>
                <div></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SpinnerComponent',
    props: ['start']
}
</script>

<style scoped>
.spin {
    z-index: 10;
    width: 100%;
    height: 100%;
    min-height: 1000px;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
}   
.ring {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: 0 auto;
    height: 100%;
}
.lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
}
.lds-dual-ring:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    margin: 8px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media screen and (max-width: 768px) {
    .ring {
        top: 40%;
        left: 35%;
    }
}
</style>