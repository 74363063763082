const resizeMasonry = (el, cols, gap) => {
  // eslint-disable-next-line no-undef
  const childrenElements = $(el).children();
  childrenElements.css("margin-bottom", gap);
  // eslint-disable-next-line no-undef
  $(el).css("column-gap", gap);

  const keys = Object.keys(cols).sort(function (a, b) { return a - b; });
  // eslint-disable-next-line no-undef
  const windowWidth = $(window).width();
  let valueMasonryWidth = `default`;

  for (let i = 0; i < keys.length; i++) {
      if(keys[i] === "default") continue;
      if (keys[i] >= windowWidth) {
          valueMasonryWidth = keys[i]; 
          break;
      }
  }
  // eslint-disable-next-line no-undef
  $(el).css("columns", `${cols[valueMasonryWidth]}`);
}

export const masonry = {
  mounted (el, binding) { 
      const { cols, gap } = binding.value;
      resizeMasonry(el, cols, gap);
      // eslint-disable-next-line no-undef
      $(document).ready(() => {
          if(window) window.addEventListener('resize', () => resizeMasonry(el, cols, gap));
      });
      
  },
  updated(el, binding){
      const { cols, gap } = binding.value;
      resizeMasonry(el, cols, gap);
  },
  beforeUnmount(el, binding){
    const { cols, gap } = binding.value;
      window.removeEventListener('resize', () => resizeMasonry(el, cols, gap));
  }
};