import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    { path: '/', name: 'home', component: () => import('@/views/HomeView') }, 
    
    { path: '/page-1/:userId', name: 'page-1', component: () => import('@/views/PageOne') }, 
    { path: '/page-2/:userId', name: 'page-2', component: () => import('@/views/PageTwo') }, 
    { path: '/page-3/:userId', name: 'page-3', component: () => import('@/views/PageThree') }, 
    { path: '/page-4/:userId', name: 'page-4', component: () => import('@/views/PageFour') }, 
    { path: '/page-5/:userId', name: 'page-5', component: () => import('@/views/PageFive') }, 
    { path: '/page-6/:userId', name: 'page-6', component: () => import('@/views/PageSix') }, 
    { path: '/page-7/:userId', name: 'page-7', component: () => import('@/views/PageSeven') }, 
    { path: '/page-8/:userId', name: 'page-8', component: () => import('@/views/PageEight') }, 
    { path: '/page-9/:userId', name: 'page-9', component: () => import('@/views/PageNine') }, 
    { path: '/page-10/:userId', name: 'page-10', component: () => import('@/views/PageTen') },
     
    { path: '/review/:userId', name: 'review', component: () => import('@/views/ReviewPage') }, 
    { path: '/page-11/:userId', name: 'page-11', component: () => import('@/views/FinalView') }, 
    
    { path: '/dashboard/:userId', name: 'dashboard', component: () => import('@/views/dashboard/DashboardView') }, 
    { path: '/dashboard/campanhas/:userId', name: 'campanhas', component: () => import('@/views/dashboard/DashboardView') }, 
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router