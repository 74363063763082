<template>
  <SpinnerComponent :start="spin.value" />
  <router-view />
</template>

<script>
import SpinnerComponent from './components/common/SpinnerComponent.vue'
export default {
  name: 'App',
  components: {
    SpinnerComponent
  },
  data() {
    return {
      spin: { value: false }
    }
  },
  provide () {
    return { makeSpin: this.spin }
  }
}
</script>

<style>
@font-face {
  font-family: 'Frutiger';
  src: url('https://res.cloudinary.com/flashvolve/raw/upload/v1709843042/fonts/agrofel/Frutiger.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Frutiger';
  src: url('https://res.cloudinary.com/flashvolve/raw/upload/v1709843062/fonts/agrofel/Frutiger-bold.ttf');
  font-weight: bold;
  font-style: normal;
}

html, body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  font-family: 'Frutiger', sans-serif;
}

@media screen and (min-width: 1024px) and (max-width: 1570px) {
  html, body {
    transform: scale(0.84);
  }
}

@media screen and (max-width: 768px) {
  html, body {
    position: relative;
    height: 100vh;
    overflow-y: auto;
  }

  #app {
    height: 100%;
  }
}
</style>
