import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import registerDirectives from '@/directives/registerDirectives';

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

const app = createApp(App)

app.config.productionTip = false

app.use(router)
app.use(registerDirectives)

app.mount('#app')